/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;
require('jqueryui');

// Bootstrap Switch
require('bootstrap-switch');
require('bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css');

// Clock Timepicker
require('jquery-clock-timepicker');

// Sweet Alert
var swal = require('sweetalert2');
require('sweetalert2/dist/sweetalert2.min.css');
global.swal = swal;

// import the function from greet.js (the .js extension is optional)
// ./ (or ../) means to look for a local file
var greet = require('./greet');

